<template>
    <div class="create-promo" v-if="getPromo && Object.keys(getPromo).length">
        <HeaderPromo class="mb-5" :class="{'mt-4': !$vuetify.breakpoint.lgAndUp}" :caption="'Создание/редактирование акции'" />

        <h3 class="title-3 mb-7">
            Добавить акцию можно как русском, так и на английском языке. 
            Выберите один язык или сразу оба и впишите в поля всё, что требуется.
        </h3>

        <v-form v-model="valid" @submit.prevent="validate" ref="card">
            <div class="grey create-promo__top lighten-4 pa-5 mb-7">
                <div class="white px-4 py-5">
                    <v-row class="my-0">
                        <v-col cols="12" md="3" class="py-0">
                            <div class="create-promo__image-container">
                                <img class="create-promo__image" v-if="getPromo.image" :src="getPromo.image"/>
                                <div class="create-promo__image create-promo__image_default" v-else>
                                    <v-icon
                                    color="#fff"
                                    x-large> 
                                        mdi-image-multiple-outline
                                    </v-icon>
                                </div>
                            </div>
                            <div class="create-promo__image-upload mt-1" v-if="$vuetify.breakpoint.lgAndUp">
                                <input class="create-promo__image-input" @change="uploadImage" type="file" name="image" accept="image/png, image/jpeg"/>
                                Загрузить <br>изображение
                            </div>
                            <v-btn
                                v-else
                                outlined
                                color="#0A467E"
                                tile
                                elevation="0"
                                :exact="true"
                                text
                                block
                                class="my-8"
                                height="44"
                            >
                                 <input class="create-promo__image-input" @change="uploadImage" type="file" name="image" accept="image/png, image/jpeg"/>
                                 Загрузить изображение
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="9" class="py-0">
                            <div class="create-promo__text-block mb-2">
                                <div class="create-promo__title mb-4">
                                    Название акции
                                </div>

                                <v-textarea
                                    v-for="lang in supportedLanguages"
                                    :key="lang.id"
                                    v-model="name[lang.code]"
                                    outlined
                                    rows="1"
                                    auto-grow
                                    :rules="[Rules.maxLength(80), Rules.notEmpty]"
                                    :label="getLabel(lang.code.toLowerCase())"
                                    background-color="#fff"
                                    color="#858585"
                                    counter="80"
                                    class="v-input--counted"
                                >
                                </v-textarea>
                            </div>
                        </v-col>
                    </v-row>
                </div>             
            </div>

            <div class="create-promo__text-block mb-10">
                <div class="create-promo__title mb-4">
                    Описание акции
                </div>
                <div
                v-for="lang in supportedLanguages"
                :key="lang.id"
                >
                    <v-textarea
                        v-model="promo_condition_text[lang.code]"
                        outlined
                        rows="1"
                        auto-grow
                        :label="getLabel(lang.code.toLowerCase())"
                        background-color="#fff"
                        color="#858585"
                        counter="1000"
                        :rules="[Rules.maxLength(1000), Rules.notEmpty]"
                        class="v-input--counted"
                    
                    >
                    </v-textarea>

                    <div class="create-promo__file">
                        <FileComponent @save="save(false)" :file="getFile(lang.id)" :lang="lang.id"/>
                    </div>

                    <v-row class="mb-7" v-if="getFile(lang.id)">
                        <v-col 
                        cols="12" 
                        md="4">
                            <v-text-field
                                v-model="promo_condition_file_text[lang.code]"
                                outlined
                                :messages="lang.code.toUpperCase() + ' текст ссылки'"
                                background-color="#fff"
                                color="#858585"
                                counter="25"
                                :rules="[Rules.maxLength(25), Rules.notEmpty]"
                                class="v-input--counted"
                                >

                            </v-text-field>
                        </v-col>
                    </v-row>
                </div>
            
            </div>
            <div class="create-promo__text-block mb-2">
                <div class="create-promo__title mb-4">
                Условия акции
                </div>
                <v-text-field
                    v-for="lang in supportedLanguages"
                    :key="lang.id"
                    v-model="link[lang.code]"
                    outlined
                    :rules="[Rules.Link]"
                    :label="lang.code.toUpperCase() + ' ссылка'"
                    background-color="#fff"
                    color="#858585"
                    class="v-input--counted mb-4"
                    placeholder="Добавьте адрес страницы акции"

                >
                </v-text-field>
                <v-row>
                    <v-col 
                    cols="12" 
                    md="4">
                        <v-combobox
                            v-for="lang in supportedLanguages"
                            :key="lang.id"
                            :items="buttonText[lang.code]"
                            v-model="link_text[lang.code]"
                            outlined
                            :messages="lang.code.toUpperCase() + ' текст кнопки'"
                            background-color="#fff"
                            color="#858585"
                            :rules="[Rules.maxLength(25)]"
                            counter="25"
                            placeholder="Выберите/введите"
                            append-icon="mdi-chevron-down"
                            class="mb-2 v-input--counted"
                            >
                        </v-combobox>
                    </v-col>
                </v-row>
            </div>

            <v-row>
                <v-col cols="12" md="4" class="js-picker">
                    <div class="create-promo__text-block mb-4">
                        <div class="create-promo__title mb-4">
                        Даты показа
                        </div>
                        <v-text-field
                            v-model="dateRangeText"
                            label="Выбрать дни"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            hide-details="true"
                            background-color="#FFFFFF"
                            @focus="openCalendar = true"
                        ></v-text-field>
                        <div class="create-promo__calendar custom-header-color" v-if="openCalendar">
                            <v-date-picker
                                v-model="dates"
                                no-title
                                range
                                color="#008FC5"
                                elevation="2"
                                header-color="#008FC5"
                                first-day-of-week="1"
                                :min="dates[0]"
                                :max="dates[1]">
                             <v-col>
                                <v-btn elevation="0"
                                color="#0A467E"
                                width="100%"
                                tile
                                dark
                                :disabled="!!!dates.length"
                                @click.stop="openCalendar = false">
                                    Сохранить
                                </v-btn>

                                <v-btn class="mt-2"
                                color="#0A467E"
                                width="100%"
                                outlined
                                tile
                                @click.stop="dates = []" >
                                    Очистить
                                </v-btn>
                            </v-col>
                            </v-date-picker>
                        </div>
                    </div>
                </v-col>

                <v-col cols="12" 
                    md="4"
                >
                    <div class="create-promo__text-block mb-4">
                        <div class="create-promo__title mb-4">
                        Категория товара
                        </div>
                        <v-select
                            v-model="category"
                            :items="Categories"
                            item-text="name"
                            multiple
                            chips
                            :rules="[Rules.notEmpty]"
                            item-value="id"
                            placeholder="Выбрать категорию"
                            append-icon="mdi-chevron-down"
                            outlined
                            hide-details="true"
                            background-color="#FFFFFF"
                        ></v-select>
                    </div>
                </v-col>
                  <v-col v-if="false"
                    cols="12" 
                    md="4"
                >
                    <div class="create-promo__text-block mb-7">
                        <div class="create-promo__title mb-4">
                        Бейджи
                        </div>
                        <v-select
                            v-model="badges[0]"
                            :items="Badges"
                            item-text="name"
                            item-value="id"
                            placeholder="Выберите бейдж"
                            append-icon="mdi-chevron-down"
                            outlined
                            
                            hide-details="true"
                            chips
                            background-color="#FFFFFF">
                            <template #selection="{ item }">
                                <v-chip class="badge__chip" :dark="!/^#([f|F]{6}|[f|F]{3}|[0]{8})$/.test(item.color)" :color="item.color">{{item.name}}</v-chip>
                            </template>
                            <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs">
                                    <v-list-item-content>
                                    <v-list-item-title>
                                        <v-row no-gutters class="flex-nowrap" align="center">
                                        <span class="badge__name">{{ item.name }}</span>
                                        <v-spacer></v-spacer>
                                        <div class="select__tools">
                                            <v-hover
                                                v-slot="{ hover }">
                                                <v-icon  
                                                :color="hover ? '#0a467e' : ''"
                                                class="mr-4"
                                                @click.stop="editBadge(item)"
                                                >
                                                    mdi-pencil
                                                </v-icon>
                                            </v-hover>
                                            <v-hover
                                                v-slot="{ hover }">
                                                <v-icon
                                                    :color="hover ? '#D21242' : ''"
                                                    @click.stop="confirmDelete(item)"
                                                >mdi-delete
                                                </v-icon>
                                            </v-hover>
                                        </div>
                                        </v-row>
                                    </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                             <template #prepend-item>
                               <div class="create-promo__add-badge px-4 py-2 mt-n2" @click="openBadgePopup()">
                                   <v-icon class="mr-2" style="color: currentColor">
                                       mdi-plus
                                   </v-icon>
                                   Добавить
                                </div>
                            </template>
                            </v-select>
                    </div>
                </v-col>
            </v-row>

            <div class="create-promo__text-block mb-7">
                <div class="create-promo__title mb-4">
                    Показывать для BN
                </div>
                <v-checkbox
                    v-for="i in userTypes"
                    :key="i.id"
                    v-model="user_types"
                    :ripple="false"
                    :label="i.name"
                    :rules="[Rules.checkboxReq]"
                    color="#008FC5"
                    :value="i.id"
                    hide-details
                ></v-checkbox>
            </div>
            
            <div class="create-promo__text-block mb-7" >
                <div class="create-promo__title mb-4">
                    Цвет фона
                </div>
                <v-row>
                    <v-col 
                    cols="9" 
                    md="4">
                        <v-text-field
                        @click="colorPicker = true"
                            v-model="bgcolor"
                            :disabled="!bgcolor"
                            label="Выберите цвет фона"
                            outlined
                            readonly
                            hide-details="true"
                            background-color="#FFFFFF"
                        >
                        </v-text-field>
                        <v-checkbox
                            v-model="noBackground"
                            :ripple="false"
                            label="Без фона"
                            color="#008FC5"
                            hide-details
                        >
                            
                        </v-checkbox>
                    </v-col>
                    <v-col
                        cols="3" 
                        md="4"
                    >
                    <div class="create-promo__bgcolor"  v-if="bgcolor" @click="colorPicker = true" :style="`background-color: ${bgcolor}`">
                       <v-dialog 
                        max-width="fit-content" 
                        :value="colorPicker"
                        @click:outside="closeColorPicker"
                        content-class="colorPickerDialog"
                        >
                            <v-card
                            color="transparent"
                            elevation="0"
                            max-width="fit-content">    
                                <v-icon
                                    @click="colorPicker = false" 
                                    class="colorPickerDialog__close d-block ml-auto"
                                    color="#FFF"
                                >
                                    mdi-close
                                </v-icon>
                                <v-color-picker
                                    v-model="bgcolor"
                                    class="ma-2"
                                    mode="hexa"
                                    show-swatches
                                   
                                ></v-color-picker>
                            </v-card>
                        </v-dialog>
                    </div>
                    </v-col>
                </v-row>
            </div>

            <div class="create-promo__text-block mb-7">
                <div class="create-promo__title">
                    Цвет текста
                </div>

                <p class="create-promo__description mb-0">
                    Подберите текст так, чтобы он не сливался:
                </p>

                <p class="create-promo__description mb-0">
                    для тёмного фона — светлый текст,  
                </p>
                <p class="create-promo__description mb-5">
                    для светлого фона — тёмный.
                </p>

                <v-radio-group  v-model="color">
                    <v-radio
                        :ripple="false"
                        label="Светлый"
                        color="#008FC5"
                        value="#FFFFFF"
                        hide-details
                    ></v-radio>
                    <v-radio
                        :ripple="false"
                        label="Тёмный"
                        color="#008FC5"
                        value="#56565A"
                        hide-details
                        class="mb-5"
                    ></v-radio>
                </v-radio-group>
            </div>
        </v-form>
        
        <AddBadge v-if="addBadgeModal" @close="closeBadgePopup" :item="editedBadge"/>
        <Dialog :title="'бейдж '+deletedItem.name" v-if="deleteConfirm" @dialogState="removeBadge"/>
    </div>
</template>

<script>
import HeaderPromo from '@/components/promo/HeaderPromo'
import FileComponent from '@/components/promo/fileComponent'
import AddBadge from '@/components/promo/addBadge'
import Dialog from '@/components/help/Dialog.vue'
import { mapActions, mapGetters } from 'vuex';
import "@/assets/scss/promoDetail.scss"
export default {
    name: "PromoDetailPromo",
    components: { 
        HeaderPromo,
        FileComponent,
        AddBadge,
        Dialog  
    },
    data: (vm) => ({
        noBackground: false,
        colorPicker: false,
        deleteConfirm: false,
        deletedItem: {},
        editedBadge: null,
        addBadgeModal: false,
        openCalendar: false,
        dates: [],
        category: [],
        badges: [],
        user_types: [],
        color: "#FFFFFF",
        bgcolor: "#FFFFFF",
        name: {},
        short_cond: {},
        promo_condition_text: {},
        link: {},
        link_text: {},
        promo_condition_file_text: {},
        valid: null,
    }),
    computed: {
        ...mapGetters(['userTypes', 'Categories', 'Badges', 'Rules', 'getPromo']),
        dateRangeText () {
            return this.formatDate.join(' – ')
        },
        supportedLanguages() {
            if(this.getPromo.country && this.getPromo.country.languages && this.getPromo.country.languages.length) {
                return this.getPromo.country.languages.map((el) => ({code: el.code, id: el.id}))
            } 
            return [{code: 'ru', id: 1}, {code: 'en', id: 2}]
        },
        formatDate() {
            return (this.dates && this.dates.length)
            ? [this.$moment(this.dates[0]).format("L"), this.$moment(this.dates[1]).format("L")]
            : [];
        },
        countryId() {
            switch(this.$route.params.country) {
                case 'ru':
                    return 1
                case 'kz':
                    return 2
                case 'ua':
                    return 3
            }
        },
         buttonText() {
             switch(this.$route.params.country) {
                case 'ru':
                    return {
                        ru: [
                            'В каталог',
                        ],
                        en: [
                            'В каталог',
                        ]
                    }
                case 'kz':
                    return  {
                        ru: [
                            'В каталог',
                        ],
                        kk: [
                            'Каталогқа',
                        ]
                    }
                case 'ua':
                    return {
                        ru: [
                            'В каталог',
                        ],
                        uk: [
                            'В каталог',
                        ]
                    }
            }
        },
    },
    methods: {
        ...mapActions([
            'fetchUserTypes', 
            'fetchCategories', 
            'fetchBadges', 
            'fetchAlerts', 
            'fetchCreatePromo',
            'fetchUpdatePromo', 
            'fetchPromo',
            'clearPromo',
            'addPromoImage',
            'deleteBadges'
        ]),
        async validate () {
            this.$refs.card.validate()
            if(this.valid) {
                this.save(true)
            } else {
                this.fetchAlerts({
                    type: 'error',
                    alerts: ["Проверьте правильность заполнения полей"]
                })
            }
        },
        fillFormData() {
            let translates = [];
            for (let lang of this.supportedLanguages) {
                translates.push({
                    language: lang.id,
                    name: this.name[lang.code],
                    promo_condition_title: "promo_condition_title",
                    short_description: this.short_cond[lang.code],
                    promo_condition_text: this.promo_condition_text[lang.code],
                    promo_condition_file_text: this.promo_condition_file_text[lang.code],
                    link: this.link[lang.code],
                    link_text: this.link_text[lang.code],
                })
            }
            return {
                available_for: this.user_types,
                background: this.bgcolor,
                badges: this.badges,
                categories: this.category, //this.category.length === 0 ? this.category : [this.category],
                color: this.color,
                end_date: this.dates[1],
                // link:null,
                start_date: this.dates[0],
                translates: translates
            }
        },
        async save(state) {
         let fd = this.fillFormData()
            await this.fetchUpdatePromo({
                body: fd,
                id: this.getPromo.id,
                type: this.getPromo.content_type,
                save: state
            })
        },
        closeColorPicker() {
            this.colorPicker = false
        },
        getTranslate(lang) {
            return this.getPromo.translates.find((el) => (el.language.id === lang))
        },
        getFile(lang_id) {
            if(this.getTranslate(lang_id) && this.getTranslate(lang_id).promo_condition_file) {
                return this.getTranslate(lang_id).promo_condition_file
            }
            return ''
        },
        async uploadImage(e) {
            this.save()
            let file = e.target.files
            if(!file && !file.length) return
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            await this.addPromoImage(formData)
            if(this.getPromo.image) {
                this.fetchUpdatePromo({
                   body: {
                       image: this.getPromo.image
                   },
                   id: this.getPromo.id,
                   type: this.getPromo.content_type
                })  
            }
            this.updatePromoInfo()
        },
        updatePromoInfo() {
            this.dates = []
            if(this.getPromo.start_date) {
                this.dates.push(this.getPromo.start_date)
            }
            if(this.getPromo.end_date) {
                this.dates.push(this.getPromo.end_date)
            }
            if(this.getPromo && this.getPromo.categories && this.getPromo.categories.length) {
                this.category = this.getPromo.categories.map((el) => (el.id))
            }
            if(this.getPromo && this.getPromo.badges && this.getPromo.badges.length) {
                this.badges = this.getPromo.badges.map((el) => (el.id))
            }
            if(this.getPromo && this.getPromo.available_for && this.getPromo.available_for.length) {
                this.user_types = this.getPromo.available_for.map((el) => (el.id))
            }
            if(this.getPromo && this.getPromo.color) {
                this.color = this.getPromo.color
            }
            if(this.getPromo) {
                this.bgcolor = this.getPromo.background
                this.noBackground = !this.getPromo.background
            }
            if(this.supportedLanguages && this.supportedLanguages.length) {
                for (let i = 0; i < this.supportedLanguages.length; i++) {
                    let lang = this.supportedLanguages[i]
                    let translates =  this.getTranslate(lang.id);
                    this.short_cond[lang.code] = translates ? translates.short_description : "";
                    this.name[lang.code] = translates ? translates.name : "";
                    this.promo_condition_text[lang.code] = translates ? translates.promo_condition_text : "";
                    this.promo_condition_file_text[lang.code] = translates ? translates.promo_condition_file_text : "";
                    this.link[lang.code] = translates ? translates.link : "";
                    this.link_text[lang.code] = translates ? translates.link_text : "";
                }
            }
        },
        getLabel(code) {
            switch (code) {
                case 'ru':
                    return 'На русском'
                case 'en':
                    return 'На английском'
            }
        },
        openBadgePopup() {
            this.addBadgeModal = true
        },
        closeBadgePopup() {
            this.editedBadge = null
            this.addBadgeModal = false
        },
        editBadge(badge) {
            this.editedBadge = badge
            this.addBadgeModal = true
        },
        confirmDelete(item) {
            this.deleteConfirm = true
            this.deletedItem = item
        },
        removeBadge(state) {
            if(state) {
                this.deleteBadges(this.deletedItem.id)
                this.deleteConfirm = false
            } else {
                this.deleteConfirm = false
            }
            
        },
        closePicker(ev) {
            if ( this.openCalendar && !ev.target.closest('.js-picker') ) this.openCalendar = false;
        },
    },
    beforeDestroy() {
        this.clearPromo();
        document.removeEventListener('click', this.closePicker, false);
    },
    async mounted() {
        window.addEventListener("beforeunload", (e) => {
            this.clearPromo()
            e.preventDefault()
        });
        if(!this.userTypes.length) {
           await this.fetchUserTypes();
        }
        if(!this.Categories.length) {
            await this.fetchCategories();
        }
        if(!this.Badges.length) {
            await this.fetchBadges();
        }
        if(!Object.keys(this.getPromo).length) {
             await this.fetchPromo({id: this.$route.params.id, type: 'promotion'})
            if(!Object.keys(this.getPromo).length) {
                this.$router.push({name: "PromoList"})
            }
            this.updatePromoInfo()
        }
        document.addEventListener('click', this.closePicker);
    },
    watch: {
        getPromo() {
            this.updatePromoInfo()
        },
        noBackground() {
            this.bgcolor = this.noBackground ? "" : "#FFFFFF"
        }
    }
}
</script>

<style scoped lang="scss">
.title-3 {
    font-family: "Source Sans Pro";
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #56565A;
}
</style>